/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect } from "react";
import { theme } from "src/theme";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useCompanyContact from "src/hooks/useCompanyContact";

const SocialMediaContact: React.FC = () => {
  const {
    redesSociais,
    setRedesSociais,
    redesSociaisToSearch,
    handleInputChange,
    handleAddSection,
    handleRemoveSection,
  } = useCompanyContact();

  useEffect(() => {
    return () => {
      setRedesSociais([]);
    };
  }, []);

  const add = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            id: value?.id ?? "",
            descricao: value?.descricao ?? "",
          },
        },
      },
      index,
      "redesSociais"
    );
  };

  const remove = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            id: "",
            descricao: "",
          },
        },
      },
      index,
      "redesSociais"
    );
  };

  return (
    <Grid container spacing={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">Redes Sociais</Typography>
        <IconButton
          color="primary"
          onClick={() => handleAddSection("redesSociais")}
        >
          <AddIcon />
        </IconButton>
      </Box>
      {redesSociais?.map((section: any, index: any) => (
        <Box key={index} marginBottom={theme.spacing(2)} display="flex">
          <Grid container spacing={2} wrap="nowrap">
            {[
              { label: "Rede Social", name: "rede" },
              { label: "Link", name: "valor" },
            ]?.map((field, idx) => {
              return (
                <Grid
                  xs={12}
                  key={idx}
                  style={{ minWidth: "500px", marginTop: 10 }}
                >
                  {field.name === "rede" && (
                    <Autocomplete
                      disablePortal
                      options={redesSociaisToSearch}
                      freeSolo
                      id="show_select_administrator_code"
                      getOptionLabel={(option: any) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        return option?.descricao || "";
                      }}
                      value={
                        redesSociais[index]?.id
                          ? redesSociaisToSearch.find(
                              (item: any) => item.id === redesSociais[index]?.id
                            ) || redesSociais[index]?.descricao.toString()
                          : null
                      }
                      size="small"
                      onChange={(_, value) => {
                        if (value) {
                          add(index, value);
                        } else {
                          remove(index);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Tipo" size="small" />
                      )}
                    />
                  )}
                  {field.name === "valor" && (
                    <TextField
                      placeholder={field.label}
                      fullWidth
                      size="small"
                      required
                      name={field.name}
                      label={field.label}
                      value={section[field.name]}
                      onChange={(e) =>
                        handleInputChange(e, index, "redesSociais")
                      }
                    />
                  )}
                </Grid>
              );
            })}
            <IconButton
              onClick={() => handleRemoveSection(index, "redesSociais")}
              style={{ marginTop: theme.spacing(1) }}
            >
              <RemoveCircleOutlineIcon color="error" />
            </IconButton>
          </Grid>
        </Box>
      ))}
    </Grid>
  );
};

export default SocialMediaContact;
