import httpClientRegister from "src/http/register";
import {
  iCompanyContact,
  iCompanyContactPage,
} from "src/interfaces/companyContact";
import { iPage } from "src/interfaces/layout";
import CompanyContact from "src/models/CompanyContact";

class companyContactConsumer {
  static get(page: iCompanyContactPage, companyId?: string) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (companyId) search.append("EmpresaId", companyId);

    return httpClientRegister.get(`/ContatoEmpresa?${search.toString()}`);
  }

  static getById(id: number) {
    return httpClientRegister.get(`/ContatoEmpresa/${id}`);
  }

  static created(companyContact: iCompanyContact) {
    return httpClientRegister.post(
      `/ContatoEmpresa`,
      CompanyContact.adapterToBody(companyContact)
    );
  }

  static updated(companyContact: iCompanyContact) {
    return httpClientRegister.put(
      `/ContatoEmpresa`,
      CompanyContact.adapterToBody(companyContact)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/ContatoEmpresa?id=${id}`);
  }

  static getType(page: iPage) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientRegister.get(`/ContatosEmpresaTipo?${search.toString()}`);
  }

  static postRedesSociais(body: any) {
    return httpClientRegister.post(`/ContatosEmpresaRedesSociais`, body);
  }
}

export default companyContactConsumer;
