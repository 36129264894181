/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DivFormsFieldLabel, MaskStyle } from "src/styles/globalStyle";
import ReactInputMask from "react-input-mask";
import useCompany from "src/hooks/useCompany";
import { ButtonSalveForm } from "src/components/ButtonsCommons";
import CircularLoading from "src/components/Loading";
import useNetwork from "src/hooks/useNetwork";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import { useParams } from "react-router-dom";
import translations from "src/_i18n/translations.json";
import { tLanguage } from "src/types/language";
import useCompanyContact from "src/hooks/useCompanyContact";
import AdditionalInformations from "./AdditionalInformations";
import SocialMedia from "./SocialMedia";

const GeneralDates: React.FC = () => {
  const {
    cnpj,
    setCnpj,
    active,
    setActive,
    socialName,
    setSocialName,
    name,
    setName,
    buyer,
    setBuyer,
    supplier,
    setSupplier,
    handleSalveOrUpdatedUser,
    loading,
    network,
    setNetwork,
    administrator,
    setAdministrator,
    handleVerifyCnpj,
    codeOutdoor,
    setCodeOutdoor,
    foreignCompany,
    setForeignCompany,
    contactCompanyFromApiSerpros,
    numeroLeitos,
    setNumeroLeitos,
    site,
    setSite,
    getEmpresaClassificacaoTipo,
    getEmpresaClassificacao,
    getRedesSociais,
  } = useCompany();

  const { getNetworks, networks } = useNetwork();
  const { getAreas, areas, getTypes, types } = useCompanyContact();

  const { lang } = useParams();
  const param = lang as keyof tLanguage;

  useEffect(() => {
    getNetworks();
    getAreas();
    getTypes();
    getEmpresaClassificacaoTipo();
    getEmpresaClassificacao();
    getRedesSociais();
  }, []);

  useEffect(() => {
    if (contactCompanyFromApiSerpros.length > 0) {
      Swal.fire({
        title: translations[param]["attention"],
        text: translations[param]["search_performed_save_changes"],
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: palette.primary.main,
        cancelButtonColor: palette.error.main,
        confirmButtonText: translations[param]["yes"],
        cancelButtonText: translations[param]["no"],
      }).then((result) => {
        if (result.isConfirmed) {
          handleSalveOrUpdatedUser(undefined, areas);
        }
      });
    }
  }, [contactCompanyFromApiSerpros]);

  if (loading) return <CircularLoading />;

  return (
    <>
      <form onSubmit={handleSalveOrUpdatedUser}>
        <Card sx={{ padding: 5, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid xs={10} sm={4} md={4} lg={4}>
              <MaskStyle>
                <DivFormsFieldLabel>
                  <FormLabel sx={{ minWidth: "20%" }}>
                    {translations[param]["cnpj"]}:{" "}
                  </FormLabel>
                  <ReactInputMask
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    placeholder={translations[param]["cnpj"]}
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                    disabled={foreignCompany}
                  />
                </DivFormsFieldLabel>
              </MaskStyle>
            </Grid>
            <Grid xs={1}>
              <IconButton
                aria-label="search"
                color="primary"
                onClick={() =>
                  Swal.fire({
                    title: translations[param]["attention"],
                    text: translations[param]["action_update_company_data"],
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: palette.primary.main,
                    cancelButtonColor: palette.error.main,
                    confirmButtonText: translations[param]["yes"],
                    cancelButtonText: translations[param]["no"],
                  }).then((result) => {
                    if (result.isConfirmed) handleVerifyCnpj(types);
                  })
                }
              >
                <SearchIcon />
              </IconButton>
            </Grid>
            <Grid xs={12} sm={4} md={4} lg={4}>
              <DivFormsFieldLabel>
                <FormLabel sx={{ minWidth: "30%" }}>
                  {translations[param]["foreign_code"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="code_outdoor"
                    name="code_outdoor"
                    label={translations[param]["foreign_code"]}
                    variant="outlined"
                    size="small"
                    value={codeOutdoor}
                    disabled={!foreignCompany}
                    onChange={(e) => setCodeOutdoor(e.target.value)}
                  />
                </FormControl>
              </DivFormsFieldLabel>
            </Grid>
            <Grid xs={2}>
              <FormControl sx={{ marginLeft: 3 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={foreignCompany}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setCodeOutdoor("");
                        }
                        setForeignCompany(e.target.checked);
                      }}
                    />
                  }
                  label={translations[param]["foreign_company"]}
                />
              </FormControl>
            </Grid>
            <Grid xs={1}>
              <FormControl sx={{ marginLeft: 3 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label={translations[param]["active"]}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <DivFormsFieldLabel>
                <FormLabel sx={{ width: 70 }}>
                  {translations[param]["social_reason"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="razaoSocial"
                    name="razaoSocial"
                    label={translations[param]["social_reason"]}
                    required
                    variant="outlined"
                    size="small"
                    value={socialName}
                    onChange={(e) => setSocialName(e.target.value)}
                  />
                </FormControl>
              </DivFormsFieldLabel>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <DivFormsFieldLabel>
                <FormLabel sx={{ width: 70 }}>
                  {translations[param]["fantasy_name"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="name"
                    name="name"
                    label={translations[param]["fantasy_name"]}
                    required
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </DivFormsFieldLabel>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Grid container xs={12} spacing={2}>
                <Grid xs={12} sm={4}>
                  <FormLabel sx={{ marginRight: 3.5, whiteSpace: "nowrap" }}>
                    {translations[param]["network"]}:
                  </FormLabel>
                  <Select
                    labelId="show_select_suppliers"
                    id="show_select_suppliers"
                    value={network}
                    size="small"
                    sx={{ width: "70%" }}
                    onChange={(event: SelectChangeEvent) => {
                      setNetwork(event.target.value as string);
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value="select" disabled>
                      {translations[param]["select"]}
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {networks.map((network) => (
                      <MenuItem key={network.id} value={network.id}>
                        {network.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid xs={12} sm={4} display="flex" alignItems="center">
                  <FormLabel>Número Leitos</FormLabel>
                  <TextField
                    id="numeroLeitos"
                    name="numeroLeitos"
                    label="Número Leitos"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    disabled={!buyer}
                    value={numeroLeitos}
                    onChange={(e) => setNumeroLeitos(e.target.value)}
                  />
                </Grid>

                <Grid xs={12} sm={4} display="flex" alignItems="center">
                  <FormLabel sx={{ marginRight: 1 }}>Site</FormLabel>
                  <TextField
                    id="site"
                    name="site"
                    label="Site"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={site}
                    onChange={(e) => setSite(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              display="flex"
              justifyContent="end"
              alignItems="center"
            >
              <Grid
                display="flex"
                justifyContent="end"
                xs={12}
                sm={5}
                spacing={2}
              >
                <Grid xs={12} sm={4}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="administrator"
                          name="administrator"
                          size="small"
                          checked={administrator}
                          onChange={(e) => setAdministrator(e.target.checked)}
                        />
                      }
                      label={translations[param]["manager"]}
                    />
                  </FormControl>
                </Grid>

                <Grid xs={12} sm={4}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="buyer"
                          name="buyer"
                          size="small"
                          checked={buyer}
                          onChange={(e) => setBuyer(e.target.checked)}
                          sx={!buyer && !supplier ? { color: "#d32f2f" } : {}}
                        />
                      }
                      label={translations[param]["buyer"]}
                    />
                  </FormControl>
                </Grid>

                <Grid xs={12} sm={4}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="supplier"
                          name="supplier"
                          size="small"
                          checked={supplier}
                          onChange={(e) => setSupplier(e.target.checked)}
                          sx={!buyer && !supplier ? { color: "#d32f2f" } : {}}
                        />
                      }
                      label={translations[param]["supplier"]}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ padding: 5, width: "100%", mt: 5 }}>
          <AdditionalInformations />
        </Card>

        <Card sx={{ padding: 5, width: "100%", mt: 5 }}>
          <SocialMedia />
        </Card>

        <Grid xs={12} sx={{ marginTop: 5 }}>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            <ButtonSalveForm
              label={translations[param]["save"]}
              type="submit"
              disabled={
                !foreignCompany &&
                ((!buyer && !supplier) ||
                  cnpj?.replace(/[^a-zA-Z0-9]/g, "").length !== 14)
              }
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default GeneralDates;
