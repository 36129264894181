/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect } from "react";
import { theme } from "src/theme";
import useCompany from "src/hooks/useCompany";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const AdditionalInformations: React.FC = () => {
  const {
    handleAddSection,
    handleRemoveSection,
    informacoesAdicionais,
    setInformacoesAdicionais,
    empresasClassificacaoTipo,
    handleInputChange,
    empresasClassificacao,
  } = useCompany();

  useEffect(() => {
    return () => {
      setInformacoesAdicionais([]);
    };
  }, []);

  const addType = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idTipo: value?.id ?? "",
            descricaoTipo: value?.descricao ?? "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };

  const removeType = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idTipo: "",
            descricaoTipo: "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };
  const addClassificacao = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idClassificacao: value?.id ?? "",
            descricaoClassificacao: value?.descricao ?? "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };

  const removeClassificacao = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idClassificacao: "",
            descricaoClassificacao: "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };

  return (
    <Grid container spacing={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">Informações Adicionais</Typography>
        <IconButton
          color="primary"
          onClick={() => handleAddSection("informacoesAdicionais")}
        >
          <AddIcon />
        </IconButton>
      </Box>
      {informacoesAdicionais?.map((section: any, index: any) => (
        <Box key={index} marginBottom={theme.spacing(2)} display="flex">
          <Grid container spacing={2} wrap="nowrap">
            {[
              { label: "Tipo", name: "tipo" },
              { label: "Classificação", name: "classificacao" },
              { label: "Informação", name: "valor" },
            ]?.map((field, idx) => {
              return (
                <Grid
                  xs={12}
                  key={idx}
                  style={{ minWidth: "350px", marginTop: 10 }}
                >
                  {field.name === "tipo" && (
                    <Autocomplete
                      disablePortal
                      options={empresasClassificacaoTipo}
                      freeSolo
                      id="show_select_administrator_code"
                      getOptionLabel={(option: any) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        return option?.descricao || "";
                      }}
                      value={
                        informacoesAdicionais[index]?.idTipo
                          ? empresasClassificacaoTipo.find(
                              (item: any) =>
                                item.id === informacoesAdicionais[index]?.idTipo
                            ) ||
                            informacoesAdicionais[
                              index
                            ]?.descricaoTipo.toString()
                          : null
                      }
                      size="small"
                      onChange={(_, value) => {
                        if (value) {
                          addType(index, value);
                        } else {
                          removeType(index);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Tipo" size="small" />
                      )}
                    />
                  )}
                  {field.name === "classificacao" && (
                    <Autocomplete
                      disablePortal
                      options={empresasClassificacao}
                      freeSolo
                      id="show_select_administrator_code"
                      getOptionLabel={(option: any) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        return option?.descricao || "";
                      }}
                      value={
                        informacoesAdicionais[index]?.idClassificacao
                          ? empresasClassificacao.find(
                              (item: any) =>
                                item.id ===
                                informacoesAdicionais[index]?.iddClassificacao
                            ) ||
                            informacoesAdicionais[index]?.descricaoClassificacao
                          : null
                      }
                      size="small"
                      onChange={(_, value) => {
                        if (value) {
                          addClassificacao(index, value);
                        } else {
                          removeClassificacao(index);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Classificação"
                          size="small"
                        />
                      )}
                    />
                  )}
                  {field.name === "valor" && (
                    <TextField
                      placeholder={field.label}
                      fullWidth
                      size="small"
                      name={field.name}
                      label={field.label}
                      value={section[field.name]}
                      onChange={(e) =>
                        handleInputChange(e, index, "informacoesAdicionais")
                      }
                    />
                  )}
                </Grid>
              );
            })}
            <IconButton
              onClick={() =>
                handleRemoveSection(index, "informacoesAdicionais")
              }
              style={{ marginTop: theme.spacing(1) }}
            >
              <RemoveCircleOutlineIcon color="error" />
            </IconButton>
          </Grid>
        </Box>
      ))}
    </Grid>
  );
};

export default AdditionalInformations;
