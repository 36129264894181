/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactInputMask from "react-input-mask";
import {
  ButtonClosedForm,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import { maskForManyTypesString } from "src/functions/text";
import useCompanyContact from "src/hooks/useCompanyContact";
import { iCompanyContact } from "src/interfaces/companyContact";
import { MaskStyle } from "src/styles/globalStyle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import useCompany from "src/hooks/useCompany";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import useLayout from "src/hooks/useLayout";
import SocialMediaContact from "./SocialMediaContact";

const Contacts: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { width } = useLayout();

  const {
    companyContactSelected,
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    validationErrorEmail,
    handleSelect,
    companyContacts,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    getCompanysContacts,
    handleSalve,
    handleUpdate,
    handleDelete,
    areas,
    area,
    setArea,
    office,
    setOffice,
    main,
    setMain,
    representative,
    setRepresentative,
    cpf,
    setCpf,
    cell,
    setCell,
    types,
    typeId,
    setTypeId,
    getRedesSociais,
  } = useCompanyContact();

  const { companySelected } = useCompany();

  const header = [
    {
      label: translations[param]["name"],
    },
    {
      label: translations[param]["phone"],
    },
    {
      label: translations[param]["email"],
    },
    {
      label: translations[param]["position"],
    },
    {
      label: translations[param]["representative"],
    },
    {
      label: translations[param]["main"],
    },
  ];

  useEffect(() => {
    getRedesSociais();
  }, []);

  useEffect(() => {
    getCompanysContacts(companySelected?.id);
  }, [page.page, page.rowsPerPage]);

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!companyContactSelected?.id) {
              handleSalve(companySelected?.id);
            } else {
              handleUpdate(companySelected?.id);
            }
          }}
        >
          <Grid container>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              item
              display="flex"
              alignItems="center"
            >
              {width >= 1450 ? (
                <FormLabel sx={{ minWidth: "7.5%" }}>
                  {translations[param]["name"]}:{" "}
                </FormLabel>
              ) : null}
              <FormControl fullWidth>
                <TextField
                  id="name"
                  name="name"
                  label={translations[param]["name"]}
                  required
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid
              xs={12}
              container
              display="flex"
              alignItems="center"
              spacing={1}
              sx={{ marginTop: "10px" }}
            >
              <Grid
                xs={12}
                sm={3}
                md={3}
                item
                display="flex"
                alignItems="center"
              >
                {width >= 1450 ? (
                  <FormLabel sx={{ minWidth: "31%" }}>
                    {translations[param]["cpf"]}:{" "}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <MaskStyle>
                    <ReactInputMask
                      mask="999.999.999-99"
                      name="cpf"
                      placeholder={translations[param]["cpf"]}
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value)}
                    />
                  </MaskStyle>
                </FormControl>
              </Grid>

              <Grid
                xs={12}
                sm={3}
                md={3}
                item
                display="flex"
                alignItems="center"
              >
                {width >= 1450 ? (
                  <FormLabel sx={{ minWidth: "20%", marginLeft: 5 }}>
                    {translations[param]["phone"]}:{" "}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <MaskStyle>
                    <ReactInputMask
                      mask="(99) 99999-9999"
                      name="phone"
                      placeholder={translations[param]["phone"]}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </MaskStyle>
                </FormControl>
              </Grid>

              <Grid
                xs={12}
                sm={3}
                md={3}
                item
                display="flex"
                alignItems="center"
              >
                {width >= 1450 ? (
                  <FormLabel sx={{ minWidth: "20%", marginLeft: 5 }}>
                    {translations[param]["cell"]}:{" "}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <MaskStyle>
                    <ReactInputMask
                      mask="(99) 99999-9999"
                      name="cell"
                      placeholder={translations[param]["cell"]}
                      value={cell}
                      onChange={(e) => setCell(e.target.value)}
                    />
                  </MaskStyle>
                </FormControl>
              </Grid>

              <Grid
                xs={12}
                sm={3}
                md={3}
                item
                display="flex"
                alignItems="center"
              >
                {width >= 1450 ? (
                  <FormLabel sx={{ minWidth: "15%", marginLeft: 5 }}>
                    {translations[param]["email"]}:{" "}
                  </FormLabel>
                ) : null}
                <TextField
                  id="email"
                  name="email"
                  label={translations[param]["email"]}
                  required
                  fullWidth
                  value={email.toLowerCase()}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  size="small"
                  error={validationErrorEmail}
                  helperText={
                    validationErrorEmail
                      ? translations[param]["invalid_email_message"]
                      : ""
                  }
                />
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              display="flex"
              alignItems="center"
              sx={{ marginTop: "10px" }}
              spacing={1}
            >
              <Grid
                xs={12}
                sm={3}
                md={3}
                display="flex"
                alignItems="center"
                item
              >
                {width >= 1450 ? (
                  <FormLabel sx={{ minWidth: "31%" }}>
                    {translations[param]["position"]}:{" "}
                  </FormLabel>
                ) : null}
                <FormControl>
                  <TextField
                    id="cargo"
                    fullWidth
                    name="cargo"
                    label={translations[param]["position"]}
                    required
                    variant="outlined"
                    size="small"
                    value={office}
                    onChange={(e) => setOffice(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid
                xs={12}
                sm={3}
                md={3}
                display="flex"
                alignItems="center"
                item
              >
                {width >= 1450 ? (
                  <FormLabel sx={{ minWidth: "20%", marginLeft: 5 }}>
                    {translations[param]["area"]}:{" "}
                  </FormLabel>
                ) : null}
                <Select
                  fullWidth
                  labelId="show_select_type_state"
                  id="show_select_type_state"
                  value={area}
                  required
                  size="small"
                  onChange={(event: SelectChangeEvent) => {
                    setArea(event.target.value as string);
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="select" disabled>
                    {translations[param]["select"]}:{" "}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {areas.map((item) => (
                    <MenuItem key={item.id} value={`${item.id}`}>
                      {item.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid
                xs={12}
                sm={2}
                md={2}
                display="flex"
                alignItems="center"
                item
              >
                {width >= 1450 ? (
                  <FormLabel sx={{ minWidth: "20%", marginLeft: 5 }}>
                    {translations[param]["type"]}:{" "}
                  </FormLabel>
                ) : null}
                <Select
                  fullWidth
                  labelId="show_select_type_state"
                  id="show_select_type_state"
                  value={typeId}
                  required
                  size="small"
                  sx={{ marginLeft: 2 }}
                  onChange={(event: SelectChangeEvent) => {
                    setTypeId(event.target.value as string);
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="select" disabled>
                    {translations[param]["select"]}:{" "}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {types.map((item: any) => (
                    <MenuItem key={item.id} value={`${item.id}`}>
                      {item.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid xs={4} display="flex" justifyContent="end">
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        name="active"
                        size="small"
                        checked={representative}
                        onChange={(e) => setRepresentative(e.target.checked)}
                      />
                    }
                    label={translations[param]["legal_representative"]}
                  />
                </FormControl>

                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        name="active"
                        size="small"
                        checked={main}
                        onChange={(e) => setMain(e.target.checked)}
                      />
                    }
                    label={translations[param]["main"]}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", marginTop: 5, marginBottom: 5 }}>
            <SocialMediaContact />
          </Box>

          <Grid container justifyContent="end" sx={{ marginTop: 3 }}>
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={() => handleSelect(null)}
            />
            <FormControl>
              {companyContactSelected?.id ? (
                <ButtonSalveForm
                  label={translations[param]["update"]}
                  type="submit"
                />
              ) : (
                <ButtonInclude
                  label={translations[param]["include"]}
                  type="submit"
                />
              )}
            </FormControl>
          </Grid>
        </form>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {companyContacts.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {companyContacts?.map((data: iCompanyContact) => (
                <TableRow
                  hover
                  key={data?.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleSelect(data)}
                  >
                    {data?.name}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {maskForManyTypesString(
                      data?.phone?.replace(/[^a-zA-Z0-9]/g, "") ?? "",
                      "(##) #####-####"
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.email}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.office}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.representative ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleSelect(data)}>
                    {data?.main ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      color="error"
                      fontSize="small"
                      onClick={() =>
                        Swal.fire({
                          title: translations[param]["attention"] + "!",
                          text: translations[param][
                            "delete_contact_confirmation"
                          ],
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["yes"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed) handleDelete(data);
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default Contacts;
